import { DashboardConfig } from "../../configSchema";
import { Dashboard } from "../../dashboard";

/**
 * Creates a function to update a specific property in the DashboardConfig.
 *
 * @template T The type of the value being updated
 * @param {keyof DashboardConfig} property The property of DashboardConfig to update
 * @param {string} id The identifier for the specific item within the property
 * @param {T} newValue The new value to set
 * @returns {(config: DashboardConfig) => DashboardConfig} A function that takes the current config and returns an updated config
 *
 * @example
 * const updateWidgetVisibility = updateConfigProperty('widgetVisibility', 'widget1', true);
 * const newConfig = updateWidgetVisibility(currentConfig);
 */
export const updateConfigProperty =
  <T>(property: keyof DashboardConfig, id: string, newValue: T) =>
  (config: DashboardConfig): DashboardConfig => ({
    ...config,
    [property]: {
      ...(config[property] as Record<string, T>),
      [id]: newValue,
    },
  });

export const updateDraftConfig =
  (db: Dashboard) =>
  (configUpdater: (config: DashboardConfig) => DashboardConfig): Dashboard => ({
    ...db,
    draftConfig: configUpdater(db.draftConfig),
  });

/**
 * Creates an initial draft config for a dashboard.
 */
export const createInitialDraftConfig = (title: string): DashboardConfig => {
  return {
    cells: {
      root: {
        id: "root",
        variant: "root",
        parentCellId: null,
        childCellIds: ["root-header"],
      },
      "root-header": {
        id: "root-header",
        variant: "header",
        parentCellId: "root",
        childCellIds: [],
      },
    },
    contents: {
      "root-header": {
        id: "root-header",
        variant: "header",
        dashboardTitle: title,
        dashboardDescription: "",
      },
      root: {
        id: "root",
        variant: "root",
        title: "Root",
        theme: "light",
        description: "",
      },
    },
    layouts: {
      "root-header": {
        id: "root-header",
        x: 0,
        y: 0,
        h: 5,
        w: 12,
        static: true,
      },
    },
    dataSources: {},
    styles: {
      "root-header": {
        id: "root-header",
        variant: "header",
        vertAlignKey: "start",
        background: {
          type: "solid",
          appColor: "bg-background",
        },
      },
      root: {
        id: "root",
        variant: "root",
        background: {
          type: "solid",
          appColor: "bg-background",
        },
        shadow: "sm",
        dashboardBackground: {
          type: "solid",
          appColor: "bg-muted",
        },
      },
    },
  };
};
