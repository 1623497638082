import { CellContent, CellVariant, ContentByVariant, DashboardConfig } from "../../configSchema";
import { findCellContentById } from "../config-selectors";
import { findCellInfoById } from "../config-selectors/cell-info";
import { contentVariantDefault } from "../defaults/cell-content";
import { updateConfigProperty } from "./config";

/**
 * Creates a new cell content object and adds it to the dashboard configuration.
 * This function generates content based on the cell variant and merges it with any custom content provided.
 */
export const createCellContent =
  <V extends CellVariant>(id: string, variant: V, customContent?: Partial<ContentByVariant<V>>) =>
  (config: DashboardConfig): DashboardConfig => {
    const newContent = {
      id,
      ...contentVariantDefault[variant],
      ...customContent,
    } as ContentByVariant<V>;
    return updateConfigProperty("contents", id, newContent)(config);
  };

/**
 * Deletes a cell content object from the dashboard configuration.
 */
export const deleteCellContent =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const newConfig = { ...config };
    delete newConfig.contents[cellId];
    return newConfig;
  };

/**
 * Recursively deletes a cell's content and all its child cells' contents from the dashboard configuration.
 */
export const recursivelyDeleteCellContent =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const cell = findCellInfoById(cellId)(config);
    if (!cell) {
      throw new Error("Cell not found");
    }
    const childCellIds = cell.childCellIds ?? [];

    // Recursively delete child cell contents
    const configWithoutChildContents = childCellIds.reduce((acc, id) => {
      return recursivelyDeleteCellContent(id)(acc);
    }, config);

    // Delete the current cell's content
    return deleteCellContent(cellId)(configWithoutChildContents);
  };

/**
 * Creates a function to update the content of a specific cell in the dashboard configuration.
 * This function is generic and type-safe, ensuring that the content update matches the cell's variant.
 * @example
 * const updateTextCell = updateCellContent<'text'>('cell-1');
 * const updatedConfig = updateTextCell({ html: '<p>New content</p>' })(currentConfig);
 */
export const updateCellContent =
  <V extends CellVariant>(cellId: string) =>
  (newContent: Partial<ContentByVariant<V>>) =>
  (config: DashboardConfig): DashboardConfig => {
    const currentCellContent = findCellContentById<V>(cellId)(config);
    const newCellContent = {
      ...currentCellContent,
      ...newContent,
      id: cellId,
    };
    return updateConfigProperty("contents", cellId, newCellContent)(config);
  };

export const updateHeaderTitleInConfig = (title: string) => (config: DashboardConfig) => {
  const newConfig = updateCellContent("root-header")({ title })(config);
  return newConfig;
};

export const createCellContentData = <V extends CellContent["variant"]>({
  id,
  variant,
  content = {},
}: {
  id: string;
  variant: V;
  content?: Partial<ContentByVariant<V>>;
}) => {
  const newContent = {
    id,
    ...contentVariantDefault[variant],
    ...content,
  } as ContentByVariant<V>;
  return newContent;
};
