import { CellInfo, DashboardConfig } from "../../configSchema";

export const findCellInfoById =
  (cellId: string) =>
  (config: DashboardConfig | null): CellInfo | undefined => {
    const cell = config?.cells[cellId];
    return cell;
  };

export const findCellParentByCellId =
  (cellId: string) =>
  (config: DashboardConfig): CellInfo | undefined => {
    const cell = findCellInfoById(cellId)(config);
    if (!cell || !cell.parentCellId) {
      throw new Error("Cell has no parent");
    }
    const parentCell = findCellInfoById(cell.parentCellId)(config);
    return parentCell;
  };

/** helper to remove undefined cells */
export const filterUndefinedInfo = (cells: (CellInfo | undefined)[]) => {
  return cells.filter((cellInfo): cellInfo is CellInfo => cellInfo !== undefined);
};

export const isAncestor =
  (descendantId: string, potentialAncestorId: string) =>
  (config: DashboardConfig): boolean => {
    let currentCellId: string | null = descendantId;
    while (currentCellId) {
      const cell = findCellInfoById(currentCellId)(config);
      if (cell?.parentCellId === potentialAncestorId) {
        return true;
      }
      currentCellId = cell?.parentCellId ?? null;
    }
    return false;
  };
