import { CellInfo, CellLayout } from "../configSchema";
import { Dashboard } from "../dashboard";
import { findCellContentById, findCellLayoutById } from "./config-selectors";

export const generateTreeData = (db: Dashboard, openAll: boolean = false) => {
  return Object.values(db.draftConfig.cells)
    .filter((cell): cell is NonNullable<typeof cell> => cell !== undefined && cell.variant !== "header")
    .map((cell) => {
      const cellContent = findCellContentById(cell.id)(db.draftConfig);
      const cellLayout = findCellLayoutById(cell.id)(db.draftConfig);
      return {
        id: cell.id,
        name: cellContent?.title ?? cell.id,
        parentId: cell.parentCellId || null,
        isLeaf: isLeaf(cell),
        isOpen: openAll,
        layout: cellLayout,
        variant: cell.variant,
      };
    });
};

const layoutVariants: CellInfo["variant"][] = ["root", "layout", "tab-layout"];

export const isLayoutVariant = (variant: CellInfo["variant"]) => {
  return layoutVariants.includes(variant);
};

export const isLeaf = (cell: CellInfo) => {
  return !isLayoutVariant(cell.variant) && cell.variant !== "tabs-panel";
};

export const dashboardCellsSortFunction = (a: { data: DashboardTreeNodeData }, b: { data: DashboardTreeNodeData }) => {
  if (a.data.layout?.y !== b.data.layout?.y) {
    return a.data.layout!.y - b.data.layout!.y;
  }
  return a.data.layout!.x - b.data.layout!.x;
};

export const isLeafFunction = (item: DashboardTreeNodeData) => {
  return item.isLeaf;
};

export type DashboardTreeNodeData = {
  id: string;
  name: string;
  parentId: string | null;
  isLeaf?: boolean;
  isOpen?: boolean;
  layout: CellLayout | undefined;
  variant: CellInfo["variant"];
};
