import { pipe } from "../../../utils/fp";
import type { CellLayout, DashboardConfig } from "../../configSchema";
import type { Dashboard } from "../../dashboard";
import { createMobileLayouts, updateLayoutsFromCellLayouts } from "../config-mutations/cell-layout";
import { resizeLayoutsToFitChildren } from "../config-mutations/layout-resize";

/**
 * Updates the cell layouts in the dashboard configuration.
 * This is cllaed from the gridLayout component when the user drags or drops a cell.
 * This function takes an array of cell layouts and updates the existing layouts in the dashboard configuration.
 * It also resizes the layouts to fit the children.
 */
export const updateCellLayouts =
  (cellLayouts: CellLayout[]) =>
  (db: Dashboard): Dashboard => {
    const newConfig = addLayoutsToConfig(cellLayouts)(db.draftConfig);
    return {
      ...db,
      draftConfig: newConfig,
    };
  };

export const addLayoutsToConfig =
  (cellLayouts: CellLayout[]) =>
  (config: DashboardConfig): DashboardConfig => {
    return pipe(
      config,
      updateLayoutsFromCellLayouts(cellLayouts),
      resizeLayoutsToFitChildren("root"),
      createMobileLayouts,
    );
  };
