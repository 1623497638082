import { dashboard as dashboardV1Domain, type tag, type user } from "@fscrypto/domain";
import {
  type BaseCellStyles,
  type CellContent,
  type CellInfo,
  type CellLayout,
  type CellStyle,
  type CellVariant,
  type Dashboard,
  type DashboardEphemeralParamData,
  type DashboardLikes,
  type DashboardTreeNodeData,
  dashboard as dashboardDomain,
} from "@fscrypto/domain/dashboard";
import * as Events from "@fscrypto/domain/events";
import {
  type Entity,
  type EntityFactory,
  type EventBus,
  type PersistentStore,
  type Store,
  createPersistentStore,
  createStore,
} from "@fscrypto/state-management";
import { DndTree } from "@fscrypto/ui";
import type { Tree, TreeEvent } from "@fscrypto/ui/src/v-tree/data/tree";
import { nanoid } from "nanoid";
import type { ProfilePublic } from "node_modules/@fscrypto/domain/src/profile/profile";
import { filter, map } from "rxjs";
import invariant from "tiny-invariant";
import { type DashboardClient, dashboardClient } from "~/features/studio-2/dashboard/data/dashboard-client";
import { type UITag, getTagIds, tagsClient } from "~/features/tags";
import * as eventBus from "~/state/events";
import { queryFactory } from "../../query/state/query";
import { v2VisualizationFactory } from "../../visualization/state/v2-visualization";
import { visualizationFactory } from "../../visualization/state/visualization";
import type { DashboardEditorState } from "../hooks/useDashboardEditor";
import { dashboardDataManager } from "./dashboard-data";

export class DashboardEntity implements Entity<Dashboard> {
  public readonly id: string;
  tabState: Map<string, Store<{ activeTabId: string | null }>> = new Map();
  store: PersistentStore<Dashboard>;
  ownerStore: Store<ProfilePublic>;
  likeStore: Store<DashboardLikes>;
  ephemeralDataStore: Store<DashboardEphemeralParamData>;
  editorStore: Store<DashboardEditorState>;
  treeStore: Store<Tree<DashboardTreeNodeData>>;
  viewerStore: Store<{ ids: string[]; fetched: boolean; loading: boolean }>;
  questEcosystemProjects?: tag.Tag[];
  dashboardTags?: tag.Tag[];
  contributors?: user.User[];
  constructor(
    private eventBus: EventBus<Events.DashboardEvent | Events.WorkItemEvent>,
    private client: DashboardClient,
    initialValue: Dashboard,
    initialOwner: ProfilePublic,
    initialLikes?: DashboardLikes,
    questEcosystemProjects?: tag.Tag[],
    dashboardTags?: tag.Tag[],
    contributors?: user.User[],
  ) {
    this.id = initialValue.id;
    this.store = createPersistentStore(
      initialValue,
      (q) => {
        this.updateDashboardTree();
        return this.#updatePersist(q);
      },
      800,
    );
    this.ownerStore = createStore(initialOwner);
    this.likeStore = createStore(initialLikes ?? { likes: 0, likedByMe: false, loading: false });
    this.viewerStore = createStore({
      ids: [] as string[],
      fetched: false as boolean,
      loading: false as boolean,
    });
    this.questEcosystemProjects = questEcosystemProjects;
    this.dashboardTags = dashboardTags;
    this.contributors = contributors;
    this.store.start();
    this.editorStore = createStore<DashboardEditorState>({
      activeCellId: null,
      activeLayoutId: "root",
      isGridActive: true,
      activeWidgetPanel: "add-widgets",
      activeConfigPanel: "settings",
      showWidgetPanel: true,
      showSettingsPanel: true,
    });
    this.ephemeralDataStore = createStore<DashboardEphemeralParamData>({});
    this.treeStore = createStore(
      new DndTree<DashboardTreeNodeData>(
        this.generateTreeData(true),
        dashboardDomain.dashboardCellsSortFunction,
        this.updateFromTreeEvent.bind(this),
        (item) => dashboardDomain.isLeafFunction(item) ?? false,
      ),
    );
    this.initiateTabState(initialValue);
    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "DASHBOARD.UPDATED.REALTIME": {
          const storeUpdatedAt = this.store.get().updatedAt.getTime();
          const realtimeUpdatedAt = e.payload.timestamp;

          if (realtimeUpdatedAt > storeUpdatedAt) {
            this.get();
          }
          break;
        }
        case "WORK_ITEM.UPDATED": {
          const { id, name } = e.payload;
          if (id === this.id) {
            this.updateTitle(name, false);
          }
          break;
        }
      }
    });
    this.setTabState = this.setTabState.bind(this);
  }
  updateDashboardTree() {
    const nodes = this.generateTreeData();

    this.treeStore.get()?.updateNodes(nodes);
  }
  generateTreeData(openAll = false): DashboardTreeNodeData[] {
    const db = this.store.get();
    const treeNodes = dashboardDomain.generateTreeData(db, openAll);
    return treeNodes;
  }
  updateFromTreeEvent(event: TreeEvent<DashboardTreeNodeData>) {
    switch (event.type) {
      case "MOVED_ITEM": {
        const { id, parentId } = event.payload;
        this.moveDashboardCell(id, parentId ?? "root");
        break;
      }
      case "RENAME_ITEM": {
        const { id, name } = event.payload;
        this.updateCellTitle(id, name);
        break;
      }
      case "REMOVED_ITEM": {
        const { id } = event.payload;
        this.removeDashboardCell(id);
        break;
      }
    }
  }
  initiateTabState(db: Dashboard) {
    const draft = dashboardDomain.findTabsPanelCells(db.draftConfig);
    const published = dashboardDomain.findTabsPanelCells(db.publishedConfig);
    for (const t of draft) {
      const firstChild = t?.childCellIds?.[0];
      this.tabState.set(`draft-${t.id}`, createStore({ activeTabId: firstChild ?? null }));
    }
    for (const t of published) {
      const firstChild = t?.childCellIds?.[0];
      this.tabState.set(`published-${t.id}`, createStore({ activeTabId: firstChild ?? null }));
    }
  }
  getTabStateStore(id: string) {
    return this.tabState.get(id);
  }
  setTabState(id: string, newId: string | null) {
    return this.tabState.get(id)?.set({ activeTabId: newId });
  }
  async get() {
    const { dashboard } = await this.client.get(this.id);
    if (!dashboard) {
      throw new Error("Dashboard not found");
    }
    this.store.set(dashboard, false);
    this.eventBus.debounceSend(Events.dashboards.updated(dashboard), 100);
  }
  async publish(options?: { tags?: UITag[] }) {
    const res = await this.client.publish(this.id);
    const publishedAt = res.publishedAt;
    if (publishedAt) {
      this.store.set({ ...this.store.get(), publishedAt });
      eventBus.eventBus.send({
        type: "TOAST.NOTIFY",
        notif: { title: "Dashboard Published", type: "success" },
      });
    }

    this.#processPublishOptions(options, Boolean(publishedAt));
  }
  async unpublish() {
    const res = await this.client.unpublish(this.id);
    const publishedAt = res.publishedAt;
    this.store.set({ ...this.store.get(), publishedAt });
    eventBus.eventBus.send({
      type: "TOAST.NOTIFY",
      notif: { title: "Dashboard UnPublished", type: "success" },
    });
  }
  // dashboard updates
  updateLayouts(gridLayout: CellLayout[]) {
    const db = this.store.get();
    const newDb = dashboardDomain.updateCellLayouts(gridLayout)(db);
    this.store.set({ ...newDb });
  }
  updateCellTitle(id: string, title: string) {
    //Step 1: update the title in the dashboard config
    const db = this.store.get();
    const newDb = dashboardDomain.updateDraftConfig(db)(dashboardDomain.updateCellContent(id)({ title }));
    this.store.set({ ...newDb });

    //Step 2: find the relevant cell
    const cell = dashboardDomain.findCellContentById<"visualization">(id)(newDb.draftConfig);

    //Step 3: update title in vis entity
    const visId = cell?.visId;

    if (visId) {
      const vis =
        visualizationFactory.visualizations.get()[visId] || v2VisualizationFactory.visualizations.get()[visId];

      if (vis) {
        return vis.updateTitle(title);
      }
    }
  }
  updateCellContent(id: string, content: Partial<CellContent>) {
    const db = this.store.get();
    const newDb = dashboardDomain.updateDraftConfig(db)(dashboardDomain.updateCellContent(id)(content));
    const newDb2 = dashboardDomain.recalculateLayouts(newDb);
    this.store.set({ ...newDb2 });
  }
  recalculateLayouts() {
    const db = this.store.get();
    const newDb = dashboardDomain.recalculateLayouts(db);
    this.store.set({ ...newDb });
  }
  updateCellStyle(id: string, style: Partial<CellStyle>) {
    const db = this.store.get();
    const newDb = dashboardDomain.updateDraftConfig(db)(dashboardDomain.updateCellStyle(id)(style));
    this.store.set(newDb);
  }
  updateCellInfo(id: string, info: Partial<CellInfo>) {
    const db = this.store.get();
    const newDb = dashboardDomain.updateDraftConfig(db)(dashboardDomain.updateCellInfo(id)(info));
    this.store.set(newDb);
  }

  update(db: Dashboard) {
    this.store.set({ ...db });
  }

  //the title can be changed by the user from the header cell so an update occurs when the title is changed
  updateTitle(title: string, persist = true) {
    const db = this.store.get();
    const newDb = dashboardDomain.updateDraftConfig(db)(
      dashboardDomain.updateCellContent("root-header")({ dashboardTitle: title }),
    );
    this.store.set({ ...newDb, title }, persist);
  }
  updateDescription(description: string) {
    const db = this.store.get();
    const newDb = dashboardDomain.updateDraftConfig(db)(
      dashboardDomain.updateCellContent("root-header")({
        dashboardDescription: description,
      }),
    );

    this.store.set({ ...newDb, description });
  }
  updateDashboardImageId(imageId: string | null) {
    this.store.set({
      ...this.store.get(),
      openGraphImageId: imageId,
      coverImageId: imageId,
    });
  }
  updateVisibility(visibility: Dashboard["visibility"]) {
    this.store.set({ ...this.store.get(), visibility });
  }
  addDashboardCell<V extends CellVariant>(args: {
    parentId: string;
    variant: V;
    layout?: Partial<CellLayout>;
    content?: Partial<CellContent>;
    style?: Partial<BaseCellStyles>;
    id?: string;
    ensureTopPosition?: boolean;
  }) {
    const db = this.store.get();
    const newId = args.id ? args.id : `${args.variant}-${nanoid(4)}`;
    const newDb = dashboardDomain.addCellToDashboard({ ...args, id: newId })(db);
    this.store.set(newDb);
    if (args.variant === "tabs-panel") {
      this.tabState.set(`draft-${newId}`, createStore({ activeTabId: null } as { activeTabId: string | null }));
    }
    this.editorStore.set({
      ...this.editorStore.get(),
      activeCellId: newId,
    });
    return newId;
  }
  addTabsCell(parentId: string) {
    const db = this.store.get();
    const tabsId = `tabs-panel-${nanoid(4)}`;
    const panelId = `tab-layout-${nanoid(4)}`;
    const newDb = dashboardDomain.addCellToDashboard({
      variant: "tabs-panel",
      parentId,
      id: tabsId,
    })(db);
    const newDb2 = dashboardDomain.addCellToDashboard({
      variant: "tab-layout",
      parentId: tabsId,
      id: panelId,
    })(newDb);
    this.store.set(newDb2);
    this.tabState.set(`draft-${tabsId}`, createStore({ activeTabId: null } as { activeTabId: string | null }));
    this.tabState.get(`draft-${tabsId}`)?.set({ activeTabId: panelId });
    return { tabsId, panelId };
  }
  async maybeAddParamsCell(parentId: string, queryId: string) {
    const db = this.store.get();
    // check what the parent variant is
    const parentVariant = dashboardDomain.findCellInfoById(parentId)(db.draftConfig)?.variant;
    if (parentVariant === "tab-layout") {
      return;
    }
    //check to see if parentId has a params cell
    // const db = this.store.get();
    const paramsCellId = dashboardDomain.findChildParamCellId(parentId)(db.draftConfig);
    if (paramsCellId) {
      return;
    }
    await dashboardDataManager.getQueryData(queryId);
    const entity = await queryFactory.getById(queryId);
    if (entity.store.get().parameters.length > 0) {
      this.addParamsCell(parentId);
    }
  }
  addParamsCell(parentId: string) {
    //only add if there is no params cell
    const db = this.store.get();
    const paramsCellId = dashboardDomain.findChildParamCellId(parentId)(db.draftConfig);
    if (!paramsCellId) {
      this.addDashboardCell({ parentId, variant: "params", ensureTopPosition: true });
    }
  }
  addVisualizationCell({
    parentId,
    visId,
    queryId,
    version,
    title,
  }: {
    parentId: string;
    visId: string;
    queryId?: string | null;
    version?: string;
    title?: string;
  }) {
    invariant(queryId, "queryId is required to create a visualization cell");
    const newId = this.addDashboardCell({
      variant: "visualization",
      parentId,
      content: {
        visId,
        queryId,
        version,
        title,
      },
    });
    this.maybeAddParamsCell(parentId, queryId);
    return newId;
  }
  moveDashboardCell(cellId: string, newParentId: string) {
    const db = this.store.get();
    const newDb = dashboardDomain.moveCellToNewParent(cellId, newParentId)(db);
    this.store.set(newDb);
  }
  removeDashboardCell(cellId: string) {
    const db = this.store.get();
    const cell = dashboardDomain.findCellInfoById(cellId)(db.draftConfig);
    const newDb = dashboardDomain.deleteCellFromDashboard(cellId)(db);
    this.store.set(newDb);
    // make sure parentCell becomes the active one
    if (cell?.parentCellId) {
      this.editorStore.set({
        ...this.editorStore.get(),
        activeCellId: cell.parentCellId,
      });
    }
  }

  updateEditorState(state: Partial<DashboardEditorState>) {
    const oldState = this.editorStore.get();
    const newState = { ...oldState, ...state };
    this.editorStore.set(newState);
  }
  async refreshDashboard(variant: "draft" | "published" = "published") {
    const config = variant === "published" ? this.store.get().publishedConfig : this.store.get().draftConfig;
    const queryIds = dashboardDomain.findAllQueryIds(this.store.get().publishedConfig);
    await queryFactory.runMultiple(queryIds);
    return queryIds;
  }

  async upgrade(version: number): Promise<Dashboard> {
    const db = await this.client.upgrade(this.id, version);
    this.store.set(db);
    return db;
  }

  async #updatePersist(value: Dashboard) {
    try {
      const res = await this.client.update(this.id, value);
      this.eventBus.send(Events.dashboards.updatedPersistSuccess(res));
      const db = this.store.get();
      return { ...db, ...res };
    } catch (e) {
      if (e instanceof Response) {
        console.error("Error updating dashboard", e);
        this.eventBus.send(
          Events.dashboards.updatedPersistFailure({
            error: e.statusText,
            statusCode: e.status,
            id: this.id,
          }),
        );
      }
      throw e;
    }
  }
  async toggleLike() {
    const likes = this.likeStore.get();

    // Toggle likes and set loading state to true optimistically
    const updatedLikes = dashboardDomain.toggleLikes(likes);
    const likesWithLoading = dashboardDomain.setLoading(updatedLikes, true);
    this.likeStore.set(likesWithLoading);

    try {
      if (likes.likedByMe) {
        await this.client.unlikeDashboard(this.id);
      } else {
        await this.client.likeDashboard(this.id);
      }
    } catch (error) {
      console.error("Error updating likes:", error);
    } finally {
      const finalLikes = dashboardDomain.setLoading(updatedLikes, false);
      this.likeStore.set(finalLikes);
    }
  }
  updateEphemeralParamValues(paramsCellId: string, paramName: string, value: string) {
    const paramData = this.ephemeralDataStore.get();
    const updatedParamData = dashboardDomain.updateEphemeralParamValues(paramData, paramsCellId, paramName, value);
    this.ephemeralDataStore.set(updatedParamData);
  }
  updateEphemeralParamRuns(paramsCellId: string) {
    const paramData = this.ephemeralDataStore.get();
    const updatedParamData = dashboardDomain.updateEphemeralParamRuns(paramData, paramsCellId);
    this.ephemeralDataStore.set(updatedParamData);
  }
  async delete() {
    try {
      await this.client.delete(this.id);
      this.eventBus.send(Events.dashboards.deleted({ id: this.id }));
    } catch (error) {
      console.error("Error deleting dashboard", error);
    }
  }
  async addViewerToDashboard(teamProfileId: string) {
    try {
      this.viewerStore.set({
        ...this.viewerStore.get(),
        ids: [teamProfileId],
        loading: true,
      });
      await this.client.addViewerToDashboard(teamProfileId, this.id);
      this.updateVisibility("private");
    } catch (e) {
      console.error("Error adding viewer to dashboard", e);
    } finally {
      this.viewerStore.set({ ...this.viewerStore.get(), loading: false });
    }
  }
  async removeViewerFromDashboard(teamProfileId: string) {
    try {
      this.viewerStore.set({ ...this.viewerStore.get(), ids: [], loading: true });
      await this.client.removeViewerFromDashboard(teamProfileId, this.id);
    } catch (e) {
      console.error("Error removing viewer from dashboard", e);
    } finally {
      this.viewerStore.set({ ...this.viewerStore.get(), loading: false });
    }
  }
  async getDashboardViewers() {
    this.viewerStore.set({ ...this.viewerStore.get(), fetched: true, loading: true });
    const viewers = await this.client.getDashboardViewers(this.id);
    this.viewerStore.set({ ...this.viewerStore.get(), ids: viewers, loading: false });
  }
  async #processPublishOptions(options: { tags?: UITag[] } | undefined, didPublish: boolean) {
    if (didPublish) {
      // Handle setting any tags
      if (options?.tags) {
        const tagIds = await getTagIds(options.tags);
        tagsClient.setOnResource("dashboard", this.id, tagIds);
      }
    }
  }
}

class DashboardFactory implements EntityFactory<DashboardEntity> {
  dashboards: Store<Record<string, DashboardEntity>> = createStore({});
  constructor(
    private client: DashboardClient,
    private eventBus: EventBus<Events.DashboardEvent | Events.WorkItemEvent>,
  ) {}

  async create(parentId?: string | null) {
    const initialData = dashboardV1Domain.createDashboardNew(parentId ?? null);
    const { dashboard, owner } = await this.client.create(initialData);
    const d = new DashboardEntity(this.eventBus, this.client, dashboard, owner);
    this.dashboards.set({ ...this.dashboards.get(), [d.id]: d });
    this.eventBus.send(Events.dashboards.created(dashboard));
    return d;
  }
  async fork(dashboardId: string) {
    const { dashboard, owner } = await this.client.fork(dashboardId);
    const d = new DashboardEntity(this.eventBus, this.client, dashboard, owner);
    this.dashboards.set({ ...this.dashboards.get(), [d.id]: d });
    return d;
  }

  from(
    dashboard: Dashboard,
    owner: ProfilePublic,
    initialLikes?: DashboardLikes,
    questEcosystemProjects?: tag.Tag[],
    dashboardTags?: tag.Tag[],
    contributors?: user.User[],
  ) {
    const d = new DashboardEntity(
      this.eventBus,
      this.client,
      dashboard,
      owner,
      initialLikes,
      questEcosystemProjects,
      dashboardTags,
      contributors,
    );
    this.dashboards.set({ ...this.dashboards.get(), [d.id]: d });
    return d;
  }

  async getById(id: string) {
    if (this.dashboards.get()[id]) {
      return this.dashboards.get()[id];
    }
    const { owner, dashboard, initialLikes } = await this.client.get(id);
    const d = new DashboardEntity(this.eventBus, this.client, dashboard, owner, initialLikes);
    this.dashboards.set({ ...this.dashboards.get(), [d.id]: d });
    return d;
  }
  from$(id: string) {
    return this.dashboards.value$.pipe(
      map((v) => v[id]),
      filter(Boolean),
    );
  }
}

export const dashboardFactory = new DashboardFactory(
  dashboardClient,
  eventBus.v2.eventBus as EventBus<Events.DashboardEvent | Events.WorkItemEvent>,
);
