import { DashboardLikes } from "../dashboard";

export const toggleLikes = (likes: DashboardLikes): DashboardLikes => {
  const updatedLikes = {
    ...likes,
    likedByMe: !likes.likedByMe,
    likes: likes.likedByMe ? likes.likes - 1 : likes.likes + 1,
  };
  return updatedLikes;
};

export const setLoading = (likes: DashboardLikes, loading: boolean): DashboardLikes => {
  const updatedLikes = {
    ...likes,
    loading,
  };
  return updatedLikes;
};
