import { CellVariant, DashboardConfig, StylesByVariant } from "../../configSchema";

export const findCellStyleById =
  <V extends CellVariant>(cellId: string) =>
  (config: DashboardConfig): StylesByVariant<V> => {
    const cell = config.styles[cellId];
    if (!cell) {
      throw new Error("Cell not found");
    }
    return cell as StylesByVariant<V>;
  };
