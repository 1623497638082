import { pipe } from "../../../utils/fp";
import { DashboardConfig } from "../../configSchema";
import { Dashboard } from "../../dashboard";
import { recursivelyDeleteCellContent } from "../config-mutations/cell-content";
import { recursivelyDeleteChildCellInfo, removeIdFromParentCell } from "../config-mutations/cell-info";
import { createMobileLayouts, recursivelyDeleteCellLayout } from "../config-mutations/cell-layout";
import { recursivelyDeleteCellStyle } from "../config-mutations/cell-style";
import { resizeLayoutsToFitChildren } from "../config-mutations/layout-resize";

/**
 * Adds a new cell to the dashboard.
 * This function creates a new cell with the given parameters and adds it to the dashboard's draft configuration.
 */
export const deleteCellFromDashboard =
  (cellId: string) =>
  (db: Dashboard): Dashboard => {
    const newConfig = deleteCellFromConfig(cellId)(db.draftConfig);
    return {
      ...db,
      draftConfig: newConfig,
    };
  };

export const deleteCellFromConfig =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    return pipe(
      config,
      recursivelyDeleteCellContent(cellId),
      recursivelyDeleteCellLayout(cellId),
      recursivelyDeleteCellStyle(cellId),
      removeIdFromParentCell(cellId),
      recursivelyDeleteChildCellInfo(cellId),
      resizeLayoutsToFitChildren("root"),
      createMobileLayouts,
    );
  };
