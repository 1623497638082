import { CellLayout, DashboardConfig } from "../../configSchema";
import { findCellInfoById } from "./cell-info";

const getLayoutsMap = (config: DashboardConfig | null, isMobile: boolean = false) =>
  isMobile ? config?.mobileLayouts ?? config?.layouts ?? {} : config?.layouts ?? {};

export const findCellLayoutById =
  (cellId: string, isMobile: boolean = false) =>
  (config: DashboardConfig | null): CellLayout | undefined => {
    return getLayoutsMap(config, isMobile)[cellId];
  };

export const findChildLayoutsFromCellId =
  (parentId: string, isMobile: boolean = false) =>
  (config: DashboardConfig | null) => {
    if (!config) return [];
    const parentCell = findCellInfoById(parentId)(config);
    const layouts = getLayoutsMap(config, isMobile);
    const childLayouts = parentCell?.childCellIds
      ?.map((id) => layouts[id])
      .filter((layout): layout is CellLayout => layout !== undefined);
    return filterUndefinedLayouts(childLayouts ?? []);
  };

/**
 * Determines the current maximum Y position of sibling layouts for a given parent ID.
 * This is used to ensure that new layouts are added below existing ones.
 */
export const findMaxYOfSiblingLayouts =
  (cellId: string, isMobile: boolean = false) =>
  (config: DashboardConfig) => {
    const childLayouts = findSiblingLayouts(cellId, isMobile)(config);
    return childLayouts.reduce((max, layout) => Math.max(max, (layout?.y ?? 0) + (layout?.h ?? 0)), 0);
  };

/**
 * Finds all othersibling layouts for a given cell ID.
 * Filter out the layout that matches the cellId
 */
export const findSiblingLayouts =
  (cellId: string, isMobile: boolean = false) =>
  (config: DashboardConfig) => {
    const cell = findCellInfoById(cellId)(config);
    const childLayouts = findChildLayoutsFromCellId(cell?.parentCellId ?? "root", isMobile)(config);
    return childLayouts
      .filter((layout): layout is CellLayout => layout !== undefined)
      .filter((layout) => layout?.id !== cellId);
  };

/**
 * Calculates the maximum height of child layouts for a given parent ID.
 */
export const calculateMaxChildHeight = (layouts: CellLayout[]) => {
  if (layouts.length === 0) return 0;
  return layouts.reduce((maxHeight, layout) => Math.max(maxHeight, layout.y + layout.h), 0);
};

const filterUndefinedLayouts = (layouts: (CellLayout | undefined)[]) => {
  return layouts.filter((layout): layout is CellLayout => layout !== undefined);
};
