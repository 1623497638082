import {
  Dashboard,
  DashboardLikes,
  DashboardNew,
  DashboardUpdate,
  dashboardLikesSchema,
  schema,
} from "@fscrypto/domain/dashboard";
import { ProfilePublic, publicSchema } from "@fscrypto/domain/profile";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";
import { z } from "zod";

const getSchema = z.object({
  dashboard: schema,
  owner: publicSchema,
  initialLikes: dashboardLikesSchema,
});

export class DashboardClient {
  constructor(private client: HttpClient) {}

  async create(payload: DashboardNew): Promise<{ dashboard: Dashboard; owner: ProfilePublic }> {
    const result = await this.client.post<{ dashboard: Dashboard; owner: ProfilePublic }>(
      $path("/api/dashboards", {}),
      payload,
    );
    return result;
  }

  async update(id: string, payload: DashboardUpdate): Promise<Dashboard> {
    const result = await this.client.post($path("/api/dashboards/:id/update", { id }), payload, schema);
    return result;
  }

  async fork(id: string): Promise<{ dashboard: Dashboard; owner: ProfilePublic }> {
    const result = await this.client.post<{ dashboard: Dashboard; owner: ProfilePublic }>(
      $path("/api/dashboards/:id/fork", { id }),
      {},
    );
    return result;
  }

  async publish(id: string): Promise<Dashboard> {
    const result = await this.client.post($path("/api/dashboards/:id/publish", { id }), {}, schema);
    return result;
  }

  async unpublish(id: string): Promise<Dashboard> {
    const result = await this.client.post($path("/api/dashboards/:id/unpublish", { id }), {}, schema);
    return result;
  }

  async get(id: string): Promise<{
    dashboard: Dashboard;
    owner: ProfilePublic;
    initialLikes: DashboardLikes;
  }> {
    const result = await this.client.get<{
      dashboard: Dashboard;
      owner: ProfilePublic;
      initialLikes: DashboardLikes;
    }>($path("/api/dashboards/:id", { id }), getSchema);
    return result;
  }

  async upgrade(id: string, version: number): Promise<Dashboard> {
    const result = await this.client.post($path("/api/dashboards/:id/upgrade/:version", { id, version }), {});
    return result as Dashboard;
  }
  async likeDashboard(id: string) {
    return this.client.post($path("/api/likes/create"), {
      resourceId: id,
      resourceType: "dashboard",
    });
  }
  async unlikeDashboard(id: string) {
    return this.client.post($path("/api/likes/delete"), {
      resourceId: id,
      resourceType: "dashboard",
    });
  }
  async delete(id: string) {
    const db = await this.client.post(`/api/dashboards/${id}/delete`, {});
    return db;
  }
  async addViewerToDashboard(teamProfileId: string, dashboardId: string) {
    const db = await this.client.post(`/api/dashboards/${dashboardId}/viewer/${teamProfileId}/create`, schema);
    return db;
  }
  async removeViewerFromDashboard(teamProfileId: string, dashboardId: string) {
    const db = await this.client.post(`/api/dashboards/${dashboardId}/viewer/${teamProfileId}/remove`, schema);
    return db;
  }
  async getDashboardViewers(dashboardId: string) {
    const result = await this.client.get<{ viewers: string[] }>(`/api/dashboards/${dashboardId}/viewers`);
    return result.viewers;
  }
}

export const dashboardClient = new DashboardClient(new Client());

// export const dummyDashboard: Dashboard = {
//   id: "dashboard_1",
//   draftConfig: {
//     cells: {
//       root: {
//         id: "root",
//         variant: "root",
//         parentCellId: null,
//         childCellIds: ["root-header", "test-layout", "test-tabs-panel", "test-viz"],
//       },
//       "root-header": {
//         id: "root-header",
//         variant: "header",
//         parentCellId: "root",
//       },
//       "test-layout": {
//         id: "test-layout",
//         variant: "layout",
//         parentCellId: "root",
//         childCellIds: ["test-image"],
//       },
//       "test-tabs-panel": {
//         id: "test-tabs-panel",
//         variant: "tabs-panel",
//         parentCellId: "root",
//         childCellIds: ["test-tab-layout"],
//       },
//       "test-image": {
//         id: "test-image",
//         variant: "image",
//         parentCellId: "test-layout",
//       },
//       "test-viz": {
//         id: "test-viz",
//         variant: "visualization",
//         parentCellId: "root",
//       },
//       "test-tab-layout": {
//         id: "test-tab-layout",
//         variant: "tab-layout",
//         parentCellId: "test-tabs-panel",
//         childCellIds: [],
//       },
//     },
//     layouts: {
//       "root-header": {
//         id: "root-header",
//         x: 0,
//         y: 0,
//         h: 7,
//         w: 6,
//       },
//       "test-layout": {
//         x: 0,
//         y: 30,
//         h: 40,
//         w: 6,
//         id: "test-layout",
//       },
//       "test-tabs-panel": {
//         x: 6,
//         y: 30,
//         h: 40,
//         w: 6,
//         id: "test-tabs-panel",
//       },
//       "test-image": {
//         x: 0,
//         y: 30,
//         h: 20,
//         w: 6,
//         id: "test-image",
//       },
//       "test-viz": {
//         x: 0,
//         y: 8,
//         h: 20,
//         w: 12,
//         id: "test-viz",
//       },
//     },
//     contents: {
//       "root-header": {
//         id: "root-header",
//         variant: "header",
//         dashboardTitle: "Some Dashboard Title",
//         dashboardDescription: "Dashboard Sub Title",
//       },
//       "test-layout": {
//         id: "test-layout",
//         variant: "layout",
//         showTitle: true,
//         showDescription: true,
//         title: "This is a Nested Layout",
//         description: "Nested Layout Description",
//       },
//       "test-image": {
//         id: "test-layout",
//         variant: "image",
//         showTitle: true,
//         showDescription: true,
//         title: "Image Panel Title",
//         description: "Image Panel Description",
//       },
//       "test-tabs-panel": {
//         id: "test-tabs-panel",
//         variant: "tabs-panel",
//         showTitle: true,
//         showDescription: true,
//         title: "Tabs Panel Title",
//         description: "Tabs Panel Description",
//       },
//       root: {
//         id: "root",
//         variant: "root",
//         title: "Root",
//       },
//       "test-viz": {
//         id: "test-viz",
//         variant: "visualization",
//         showTitle: false,
//         showDescription: false,
//         title: "Viz Panel Title",
//         description: "Viz Panel Description",
//         queryId: "e26f1557-9b7b-4337-a617-8f83106a9894",
//         visId: "eb8784bd-05fa-43bf-96f2-072b28a6245b",
//       },
//       "test-tab-layout": {
//         id: "test-tab-layout",
//         variant: "tab-layout",
//         showTitle: true,
//         showDescription: true,
//         title: "Tab Layout Title",
//       },
//     },
//     styles: {
//       "root-header": {
//         id: "root-header",
//         vertAlignKey: "start",
//         variant: "header",
//       },
//     },
//     dataSources: {},
//   },
//   publishedConfig: null,
// };
