import { CellVariant, ContentByVariant, DashboardConfig } from "../../configSchema";

export const findCellContentById =
  <V extends CellVariant>(cellId: string) =>
  (config: DashboardConfig): ContentByVariant<V> => {
    const cell = config.contents[cellId];
    if (!cell) {
      throw new Error("Cell content not found");
    }
    return cell as ContentByVariant<V>;
  };
