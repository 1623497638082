import { nanoid } from "nanoid";
import { applyIf, pipe } from "../../../utils/fp";
import { CellContent, CellLayout, CellStyle, CellVariant, DashboardConfig } from "../../configSchema";
import { Dashboard } from "../../dashboard";
import { createCellContent } from "../config-mutations/cell-content";
import { addIdToParentCell, createCellInfo } from "../config-mutations/cell-info";
import { createCellLayout, createMobileLayouts, moveExistingLayoutsDown } from "../config-mutations/cell-layout";
import { createCellStyle } from "../config-mutations/cell-style";
import { resizeLayoutsToFitChildren } from "../config-mutations/layout-resize";

/**
 * Adds a new cell to the dashboard.
 * This function creates a new cell with the given parameters and adds it to the dashboard's draft configuration.
 */
export const addCellToDashboard =
  (args: AddCellToDashboardParams) =>
  (db: Dashboard): Dashboard => {
    const newConfig = addNewCellToConfig(args)(db.draftConfig);
    return {
      ...db,
      draftConfig: newConfig,
    };
  };

/**
 * Adds a new cell to the dashboard configuration.
 * This function creates all necessary components of a cell (info, content, style, layout)
 * and adds them to the configuration.
 */
export const addNewCellToConfig =
  ({
    variant,
    parentId = "root",
    layout = {},
    content,
    style,
    id,
    ensureTopPosition = false,
  }: AddCellToDashboardParams) =>
  (config: DashboardConfig): DashboardConfig => {
    // Create a new unique ID for the cell and associated elements if not provided
    const newId = id ? id : `${variant}-${nanoid(4)}`;

    return pipe(
      config,
      createCellInfo(newId, variant, parentId),
      addIdToParentCell(newId, parentId),
      createCellContent(newId, variant, content),
      createCellStyle(newId, variant, style),
      createCellLayout({ newId, variant, layout, parentId, ensureTopPosition }),
      applyIf(() => ensureTopPosition, moveExistingLayoutsDown({ newId })),
      resizeLayoutsToFitChildren("root"),
      createMobileLayouts,
    );
  };

/**
 * Type definition for the parameters required to add a new cell to the dashboard.
 */
type AddCellToDashboardParams = {
  id?: string;
  variant: CellVariant;
  parentId?: string;
  layout?: Partial<CellLayout>;
  content?: Partial<CellContent>;
  style?: Partial<CellStyle>;
  ensureTopPosition?: boolean;
};
