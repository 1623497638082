import { CellContent, CellStyle, CellVariant, ContentByVariant, StylesByVariant } from "../configSchema";

export function isCellContentVariant<V extends CellVariant>(
  content: CellContent,
  variant: V,
): content is ContentByVariant<V> {
  return content?.variant === variant;
}

export const isCellStyleVariant = <V extends CellStyle["variant"]>(
  style: CellStyle,
  variant: V,
): style is StylesByVariant<V> => {
  return style.variant === variant;
};
