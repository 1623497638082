import { CellInfo, CellVariant, DashboardConfig } from "../../configSchema";
import { findCellInfoById } from "../config-selectors/cell-info";
import { updateConfigProperty } from "./config";

/**
 * Creates a new cell info object and adds it to the dashboard configuration.
 * This function generates a new cell with the specified ID, variant, and parent ID.
 */
export const createCellInfo =
  (id: string, variant: CellVariant, parentId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const newCell: CellInfo = {
      id,
      variant,
      childCellIds: [],
      parentCellId: parentId,
    };
    return updateConfigProperty("cells", id, newCell)(config);
  };

/**
 * Adds a child cell ID to the parent cell's childCellIds array.
 * This function updates the parent cell to include the new child cell ID.
 */
export const addIdToParentCell =
  (id: string, parentId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const parentCell = findCellInfoById(parentId)(config);

    return updateConfigProperty("cells", parentId, {
      ...parentCell,
      childCellIds: [...(parentCell?.childCellIds ?? []), id],
    })(config);
  };

/**
 * Removes a child cell ID from the parent cell's childCellIds array.
 * This function updates the parent cell by filtering out the specified child cell ID.
 */
export const removeIdFromParentCell =
  (id: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const parentId = findCellInfoById(id)(config)?.parentCellId;
    if (!parentId) {
      throw new Error("Parent Id not found");
    }
    const parentCell = findCellInfoById(parentId)(config);

    return updateConfigProperty("cells", parentId, {
      ...parentCell,
      childCellIds: (parentCell?.childCellIds ?? []).filter((childId) => childId !== id),
    })(config);
  };

/**
 * Recursively deletes a cell and all its child cells from the dashboard configuration.
 */
export const recursivelyDeleteChildCellInfo =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const cell = findCellInfoById(cellId)(config);
    if (!cell) {
      throw new Error("Cell not found");
    }
    const childCellIds = cell.childCellIds ?? [];

    // Recursively delete child cell contents
    const configWithoutChildContents = childCellIds.reduce((acc, id) => {
      return recursivelyDeleteChildCellInfo(id)(acc);
    }, config);

    // Delete the current cell's content
    return deleteCellInfo(cellId)(configWithoutChildContents);
  };

/**
 * Deletes a cell from the dashboard configuration.
 */
export const deleteCellInfo =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const newConfig = { ...config };
    delete newConfig.cells[cellId];
    return newConfig;
  };

/**
 * Updates the parentCellId of a cell.
 */
export const updateParentId =
  (cellId: string, newParentId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const cell = findCellInfoById(cellId)(config);
    if (!cell) {
      throw new Error("Cell not found");
    }
    return updateConfigProperty("cells", cellId, {
      ...cell,
      parentCellId: newParentId,
    })(config);
  };

/**
 * Adds a cell ID to the new parent's childCellIds array.
 */
export const addIdToNewParentChildCellIds =
  (cellId: string, newParentId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const newParentCell = findCellInfoById(newParentId)(config);
    if (!newParentCell) {
      return config;
    }

    const updatedChildCellIds = [...(newParentCell.childCellIds || []), cellId];

    return updateConfigProperty("cells", newParentId, {
      ...newParentCell,
      childCellIds: updatedChildCellIds,
    })(config);
  };

/**
 * Creates a function to update the info of a specific cell in the dashboard configuration.
 * This function is generic and type-safe, ensuring that the cell info update matches the cell's structure.
 * @example
 * const updateInfo = updateCellInfo('cell-1');
 * const updatedConfig = updateInfo({ variant: 'chart', childCellIds: ['child-1', 'child-2'] })(currentConfig);
 */
export const updateCellInfo =
  (cellId: string) =>
  (newInfo: Partial<CellInfo>) =>
  (config: DashboardConfig): DashboardConfig => {
    const currentCellInfo = findCellInfoById(cellId)(config);
    if (!currentCellInfo) {
      throw new Error(`Cell info not found for cell: ${cellId}`);
    }
    return updateConfigProperty("cells", cellId, {
      ...currentCellInfo,
      ...newInfo,
      id: cellId,
    })(config);
  };
