import { CellVariant, ContentByVariant } from "../../configSchema";

export const contentVariantDefault: {
  [V in CellVariant]: Omit<ContentByVariant<V>, "id">;
} = {
  "tab-layout": {
    variant: "tab-layout",
    title: "Tabs Layout",
    description: "Tabs Layout Description",
    showDescription: false,
    showTitle: false,
  },
  "tabs-panel": {
    variant: "tabs-panel",
    title: "Tabs Panel",
    description: "Tabs Panel Description",
    showDescription: true,
    showTitle: true,
  },
  text: {
    variant: "text",
    title: "Text Panel",
    description: "Text Panel Description",
    showDescription: true,
    showTitle: true,
    html: "",
  },
  image: {
    variant: "image",
    title: "Image Panel",
    description: "Image Panel Description",
    showDescription: false,
    showTitle: false,
  },
  root: {
    variant: "root",
    title: "Root",
    description: "Root Description",
    showDescription: false,
    showTitle: false,
  },
  layout: {
    variant: "layout",
    title: "Layout",
    description: "Layout Description",
    showDescription: false,
    showTitle: false,
  },
  header: {
    variant: "header",
    showDescription: true,
    showTitle: true,
    dashboardTitle: "",
    dashboardDescription: "",
  },
  visualization: {
    variant: "visualization",
    visId: "",
    queryId: "",
  },
  "legacy-table": {
    variant: "legacy-table",
    queryId: "",
    title: "Table",
  },
  base: {
    variant: "base",
  },
  heading: {
    variant: "heading",
    title: "Heading",
    description: "Heading Description",
    showDescription: true,
    showTitle: true,
  },
  params: {
    variant: "params",
    title: "Parameters",
    description: "Parameters Description",
    showDescription: false,
    showTitle: false,
  },
};
