export type {
  Cell,
  Component,
  ComponentType,
  Dashboard,
  DashboardCopyToProfile,
  DashboardNew,
  DashboardPublished,
  DashboardTab,
  DashboardUpdate,
  Styles,
  DashboardDiscovered,
  DashboardLikes,
} from "./dashboard";
export {
  schema,
  discoverSchema,
  cellContainerSchema,
  newSchema,
  updateSchema,
  publishedSchema,
  addVizSchema,
  copyToProfileSchema,
  dashboardLikesSchema,
  // V3 functions
  createDashboardNew,
} from "./dashboard";

import * as dashboardParameters from "./dashboard-parameters-new";
import * as dashboardParams from "./dashboard-params";
import * as mutations from "./utils/config-mutations";
import * as configOrchestrations from "./utils/config-orchestrations";
import * as selectors from "./utils/config-selectors";
import * as dashboardConfig from "./utils/dashboard-config";
import * as dashboardLikes from "./utils/dashboard-likes";
import * as dashboardTree from "./utils/dashboard-tree";
import * as guards from "./utils/guards";

export const dashboard = {
  ...guards,
  ...dashboardConfig,
  ...dashboardParams,
  ...dashboardLikes,
  ...dashboardParameters,
  ...dashboardTree,
  ...configOrchestrations,
  ...selectors,
  ...mutations,
};

export type {
  CellVariant,
  CellLayout,
  CellInfo,
  VariantContentMap,
  ContentByVariant,
  CellContent,
  HeaderCellContent,
  ImageCellContent,
  VariantStylesMap,
  StylesByVariant,
  CellStyle,
  HeaderCellStyles,
  ImageCellStyles,
  TextCellStyles,
  BaseCellStyles,
  LayoutCellStyles,
  TextCellContent,
  VisualizationCellContent,
  LegacyTableCellContent,
  DashboardView,
} from "./configSchema";

export type { DashboardParams, LayoutParams } from "./dashboard-params";
export type { DashboardEphemeralParamData } from "./dashboard-parameters-new";
export type { DashboardTreeNodeData } from "./utils/dashboard-tree";
