import { pipe } from "../../../utils/fp";
import { CellLayout, CellVariant, DashboardConfig } from "../../configSchema";
import { findCellInfoById } from "../config-selectors/cell-info";
import {
  findCellLayoutById,
  findChildLayoutsFromCellId,
  findMaxYOfSiblingLayouts,
  findSiblingLayouts,
} from "../config-selectors/cell-layout";
import { layoutVariantDefaults } from "../defaults/cell-layout";
import { updateConfigProperty } from "./config";
import { resizeLayoutsToFitChildren } from "./layout-resize";

type CreateCellLayoutParams = {
  newId: string;
  variant: CellVariant;
  layout?: Partial<CellLayout>;
  parentId: string;
  ensureTopPosition: boolean;
};

/**
 * Creates a new cell layout in the dashboard configuration.
 * This function determines the appropriate strategy based on the cell variant
 * and applies it to create the new layout.
 */
export const createCellLayout =
  ({
    newId,
    variant,
    layout,
    parentId,
    ensureTopPosition,
  }: {
    newId: string;
    variant: CellVariant;
    layout: Partial<CellLayout>;
    parentId: string;
    ensureTopPosition: boolean;
  }) =>
  (config: DashboardConfig) => {
    const strategy = variant === "tab-layout" ? tabLayoutStrategy : defaultStrategy;
    const newLayout = strategy({ newId, parentId, layout, ensureTopPosition, variant })(config);
    return updateConfigProperty("layouts", newId, newLayout)(config);
  };

type CreateCellLayoutStrategy = (params: CreateCellLayoutParams) => (config: DashboardConfig) => CellLayout;

/**
 * Strategy for creating a tab layout.
 * This function sets up a new tab layout with full width and consistent height.
 */
const tabLayoutStrategy: CreateCellLayoutStrategy =
  ({ newId, parentId, variant }) =>
  (config: DashboardConfig) => {
    const siblingLayouts = findSiblingLayouts(parentId)(config);
    const siblingHeight = siblingLayouts[0]?.h ?? 30;
    return { id: newId, x: 0, y: 0, w: 12, h: siblingHeight, variant };
  };

/**
 * Default strategy for creating cell layouts.
 * This function determines the position and size of the new cell layout,
 * taking into account existing layouts and user preferences.
 */
const defaultStrategy: CreateCellLayoutStrategy =
  ({ newId, variant, layout, ensureTopPosition }) =>
  (config: DashboardConfig) => {
    const defaultSize = layoutVariantDefaults[variant] ?? { w: 6, h: 6 };
    const maxY = findMaxYOfSiblingLayouts(newId)(config);
    return {
      id: newId,
      x: layout?.x ?? 0,
      y: ensureTopPosition ? 0 : layout?.y ?? maxY,
      w: layout?.w ?? defaultSize.w,
      h: layout?.h ?? defaultSize.h,
      variant,
    };
  };

/**
 * Moves existing layouts down to accommodate a new cell layout.
 * This function adjusts the vertical position of sibling layouts
 * when a new cell is inserted at the top of the dashboard.
 */
export const moveExistingLayoutsDown =
  ({ newId }: { newId: string }) =>
  (config: DashboardConfig): DashboardConfig => {
    const newCellLayout = config.layouts[newId];
    console.log("newId", newCellLayout);
    if (!newCellLayout) {
      throw new Error("New cell layout not found");
    }
    const siblingLayouts = findSiblingLayouts(newId)(config);
    // Move all layouts down by the height of the new cell layout
    const updatedLayouts = siblingLayouts.reduce(
      (acc, layout) => {
        acc[layout.id] = { ...layout, y: layout.y + newCellLayout.h };
        return acc;
      },
      {} as Record<string, CellLayout>,
    );
    return { ...config, layouts: { ...config.layouts, ...updatedLayouts } };
  };

/**
 * Deletes a cell's layout from the dashboard configuration.
 */
export const deleteCellLayout =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const { [cellId]: _, ...remainingLayouts } = config.layouts;
    return { ...config, layouts: remainingLayouts };
  };

/**
 * Recursively deletes a cell's layout and all its child cells' layouts from the dashboard configuration.
 */
export const recursivelyDeleteCellLayout =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const cell = findCellInfoById(cellId)(config);
    if (!cell) {
      throw new Error("Cell not found");
    }
    const childCellIds = cell.childCellIds ?? [];

    // Recursively delete child cell layouts
    const configWithoutChildLayouts = childCellIds.reduce((acc, id) => {
      return recursivelyDeleteCellLayout(id)(acc);
    }, config);

    // Delete the current cell's layout
    return deleteCellLayout(cellId)(configWithoutChildLayouts);
  };

/**
 * Updates the layouts in the dashboard configuration with new cell layouts.
 * This function takes an array of cell layouts and updates the existing layouts in the dashboard configuration.
 */
export const updateLayoutsFromCellLayouts =
  (cellLayouts: CellLayout[]) =>
  (config: DashboardConfig): DashboardConfig => {
    const updatedLayouts = cellLayouts.reduce(
      (acc, layout) => {
        acc[layout.id] = layout;
        return acc;
      },
      {} as Record<string, CellLayout>,
    );
    return { ...config, layouts: { ...config.layouts, ...updatedLayouts } };
  };

/**
 * Creates a function to update the layout of a specific cell in the dashboard configuration.
 * This function is generic and type-safe, ensuring that the layout update matches the cell's variant.
 * @example
 * const updateLayout = updateCellLayout('cell-1');
 * const updatedConfig = updateLayout({ x: 0, y: 0, w: 6, h: 4 })(currentConfig);
 */
export const updateCellLayout =
  (cellId: string) =>
  (newLayout: Partial<CellLayout>) =>
  (config: DashboardConfig): DashboardConfig => {
    const currentCellLayout = findCellLayoutById(cellId)(config);
    if (!currentCellLayout) {
      throw new Error(`Layout not found for cell: ${cellId}`);
    }
    return updateConfigProperty("layouts", cellId, {
      ...currentCellLayout,
      ...newLayout,
      id: cellId,
    })(config);
  };

/**
 * Creates mobile layouts and resizes them to fit their children.
 */
export const createMobileLayouts = (config: DashboardConfig): DashboardConfig => {
  const newConfig = pipe(config, makeLayoutsFullWidth, resizeLayoutsToFitChildren("root", "mobileLayouts"));
  return newConfig;
};

export const makeLayoutsFullWidth = (config: DashboardConfig): DashboardConfig => {
  const processLayouts = (parentId: string, currentY: number): [Record<string, CellLayout>, number] => {
    const childLayouts = findChildLayoutsFromCellId(parentId)(config);
    const sortedLayouts = sortLayoutItemsByRowCol(childLayouts);

    let mobileLayouts: Record<string, CellLayout> = {};
    let newY = currentY;

    sortedLayouts.forEach((layout) => {
      const cell = findCellInfoById(layout.id)(config);
      mobileLayouts[layout.id] = {
        ...layout,
        x: 0,
        y: newY,
        w: 12,
      };

      if (cell?.variant === "layout" || cell?.variant === "tabs-panel" || cell?.variant === "tab-layout") {
        const [nestedLayouts] = processLayouts(layout.id, 0);
        mobileLayouts = { ...mobileLayouts, ...nestedLayouts };
      }

      newY += layout.h;
    });

    return [mobileLayouts, newY];
  };

  const [mobileLayouts] = processLayouts("root", 0);

  return {
    ...config,
    mobileLayouts: mobileLayouts,
  };
};

export const sortLayoutItemsByRowCol = (layout: CellLayout[]): CellLayout[] => {
  //clone array as sort modifies
  return [...layout].sort(function (a, b) {
    if (a.y > b?.y || (a.y === b.y && a.x > b.x)) {
      return 1;
    } else if (a.y === b.y && a.x === b.x) {
      return 0;
    }
    return -1;
  });
};
