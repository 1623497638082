import { pipe } from "../../../utils/fp";
import { CellLayout, DashboardConfig } from "../../configSchema";
import { findCellContentById } from "../config-selectors";
import { findCellInfoById } from "../config-selectors/cell-info";
import {
  calculateMaxChildHeight,
  findCellLayoutById,
  findChildLayoutsFromCellId,
} from "../config-selectors/cell-layout";
import { updateConfigProperty } from "./config";

export const resizeLayoutsToFitChildren =
  (cellId: string, layoutsProperty: "layouts" | "mobileLayouts" = "layouts") =>
  (config: DashboardConfig): DashboardConfig => {
    return pipe(config, resizeChildLayouts(cellId, layoutsProperty), resizeLayoutIfNeeded(cellId, layoutsProperty));
  };

const resizeLayoutIfNeeded =
  (cellId: string, layoutsProperty: "layouts" | "mobileLayouts") =>
  (config: DashboardConfig): DashboardConfig => {
    const layout = config[layoutsProperty]?.[cellId];
    const strategy = getResizeStrategy(config, cellId, layoutsProperty);
    if (!strategy.shouldCalculate || !layout) return config;

    const newHeight = strategy.calculateHeight();
    if (layout.h !== newHeight) {
      return updateConfigProperty(layoutsProperty, cellId, {
        ...layout,
        h: newHeight,
      })(config);
    }

    return config;
  };

const resizeChildLayouts =
  (cellId: string, layoutsProperty: "layouts" | "mobileLayouts") =>
  (config: DashboardConfig): DashboardConfig => {
    const cell = findCellInfoById(cellId)(config);
    return (
      cell?.childCellIds?.reduce((acc, childId) => resizeLayoutsToFitChildren(childId, layoutsProperty)(acc), config) ??
      config
    );
  };

export const shouldResizeLayout = (layout: CellLayout, maxChildHeight: number) => {
  return maxChildHeight !== layout.h;
};

type ResizeStrategy = {
  calculateHeight(): number;
  shouldCalculate: Boolean;
};

const defaultLayoutStrategy: ResizeStrategy = {
  calculateHeight(): number {
    return 0;
  },
  shouldCalculate: false,
};

const layoutResizeStrategy = (
  config: DashboardConfig,
  cellId: string,
  layoutsProperty: "layouts" | "mobileLayouts",
): ResizeStrategy => {
  const layout = findCellLayoutById(cellId)(config);

  if (!layout) {
    return defaultLayoutStrategy;
  }

  return {
    calculateHeight(): number {
      const childLayouts = findChildLayoutsFromCellId(cellId, layoutsProperty === "mobileLayouts")(config);
      const content = findCellContentById(cellId)(config);
      const hasTitle = !!content?.showTitle;
      const hasDescription = !!content?.showDescription;
      const extraHeight = hasTitle || hasDescription ? 4 : 2;
      return Math.max(calculateMaxChildHeight(childLayouts) + extraHeight, 16);
    },
    shouldCalculate: true,
  };
};

const tabsPanelResizeStrategy = (
  config: DashboardConfig,
  cellId: string,
  layoutsProperty: "layouts" | "mobileLayouts",
): ResizeStrategy => {
  const layout = findCellLayoutById(cellId)(config);
  const content = findCellContentById(cellId)(config);

  if (!layout || content?.variant !== "tabs-panel") {
    return defaultLayoutStrategy;
  }

  return {
    calculateHeight(): number {
      const childLayouts = findChildLayoutsFromCellId(cellId, layoutsProperty === "mobileLayouts")(config);
      const maxPanelHeight = Math.max(
        ...childLayouts.map((layout) => {
          const childLayouts = findChildLayoutsFromCellId(layout.id, layoutsProperty === "mobileLayouts")(config);
          return calculateMaxChildHeight(childLayouts);
        }),
      );

      const hasTitle = !!content.showTitle;
      const hasDescription = !!content.showDescription;
      const tabButtonsHeight = 3.5; // Adjust this value based on your tab button height
      const extraHeight = (hasTitle || hasDescription ? 4.5 : 1.5) + tabButtonsHeight;

      return Math.max(maxPanelHeight + extraHeight, 20);
    },
    shouldCalculate: true,
  };
};

const getResizeStrategy = (
  config: DashboardConfig,
  cellId: string,
  layoutsProperty: "layouts" | "mobileLayouts",
): ResizeStrategy => {
  const cellContent = findCellContentById(cellId)(config);
  switch (cellContent?.variant) {
    case "layout":
      return layoutResizeStrategy(config, cellId, layoutsProperty);
    case "tabs-panel":
      return tabsPanelResizeStrategy(config, cellId, layoutsProperty);
    case "header":
      return {
        calculateHeight: () => (layoutsProperty === "mobileLayouts" ? 6 : 5),
        shouldCalculate: true,
      };
    default:
      return defaultLayoutStrategy;
  }
};
