import { CellVariant, DashboardConfig, StylesByVariant } from "../../configSchema";
import { findCellStyleById } from "../config-selectors";
import { findCellInfoById } from "../config-selectors/cell-info";
import { styleVariantDefaults } from "../defaults/cell-style";
import { updateConfigProperty } from "./config";

/**
 * Creates a new cell style object and adds it to the dashboard configuration.
 * This function generates a style based on the cell variant, merges it with default styles,
 * and then applies any custom styles provided.
 */
export const createCellStyle =
  <V extends CellVariant>(id: string, variant: V, customStyle?: Partial<StylesByVariant<V>>) =>
  (config: DashboardConfig): DashboardConfig => {
    const newStyle = {
      id,
      variant,
      ...styleVariantDefaults[variant],
      ...customStyle,
    } as StylesByVariant<V>;
    return updateConfigProperty("styles", id, newStyle)(config);
  };

/**
 * Deletes a cell's style from the dashboard configuration.
 */
export const deleteCellStyle =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const newConfig = { ...config };
    delete newConfig.styles[cellId];
    return newConfig;
  };

/**
 * Recursively deletes a cell's style and all its child cells' styles from the dashboard configuration.
 */
export const recursivelyDeleteCellStyle =
  (cellId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    const cell = findCellInfoById(cellId)(config);
    if (!cell) {
      throw new Error("Cell not found");
    }
    const childCellIds = cell.childCellIds ?? [];

    // Recursively delete child cell styles
    const configWithoutChildStyles = childCellIds.reduce((acc, id) => {
      return recursivelyDeleteCellStyle(id)(acc);
    }, config);

    // Delete the current cell's style
    return deleteCellStyle(cellId)(configWithoutChildStyles);
  };

/**
 * Creates a function to update the style of a specific cell in the dashboard configuration.
 * This function is generic and type-safe, ensuring that the style update matches the cell's variant.
 * @example
 * const updateStyle = updateCellStyle('cell-1', 'chart');
 * const updatedConfig = updateStyle({ backgroundColor: 'blue' })(currentConfig);
 */
export const updateCellStyle =
  <V extends CellVariant>(cellId: string) =>
  (newData: Partial<StylesByVariant<V>>) =>
  (config: DashboardConfig | null): DashboardConfig => {
    if (!config) {
      throw new Error("Dashboard config not found");
    }
    const currentCellStyle = findCellStyleById(cellId)(config);

    const newStyle = {
      ...currentCellStyle,
      ...newData,
      id: cellId,
    } as StylesByVariant<V>;
    return updateConfigProperty("styles", cellId, newStyle)(config);
  };
