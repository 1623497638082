import { pipe } from "../../../utils/fp";
import { DashboardConfig } from "../../configSchema";
import { Dashboard } from "../../dashboard";
import { createMobileLayouts } from "../config-mutations/cell-layout";
import { resizeLayoutsToFitChildren } from "../config-mutations/layout-resize";

/**
 * Recalculates and updates the cell layouts in the dashboard configuration.
 * This function is called from the GridLayout component when the user drags or resizes a cell.
 * It updates the existing layouts in the dashboard configuration and resizes them to fit their children.
 * The function also generates mobile layouts based on the updated configuration.
 */
export const recalculateLayouts = (db: Dashboard): Dashboard => {
  const newConfig = recalculateConfigLayouts(db.draftConfig);
  return {
    ...db,
    draftConfig: newConfig,
  };
};

const recalculateConfigLayouts = (config: DashboardConfig): DashboardConfig => {
  return pipe(config, resizeLayoutsToFitChildren("root"), createMobileLayouts);
};
