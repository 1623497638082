import { pipe } from "../../../utils/fp";
import { DashboardConfig } from "../../configSchema";
import { Dashboard } from "../../dashboard";
import { createMobileLayouts } from "../config-mutations";
import { addIdToNewParentChildCellIds, removeIdFromParentCell, updateParentId } from "../config-mutations/cell-info";
import { resizeLayoutsToFitChildren } from "../config-mutations/layout-resize";

/**
 * This moves a cell from one layout/parent to another one
 * It's a bit of a misnomer, since it's not really moving the cell, but rather deleting it from its current parent and adding it to the new parent
 */
export const moveCellToNewParent =
  (cellId: string, newParentId: string) =>
  (db: Dashboard): Dashboard => {
    const newConfig = moveCellInConfig(cellId, newParentId)(db.draftConfig);
    return {
      ...db,
      draftConfig: newConfig,
    };
  };

export const moveCellInConfig =
  (cellId: string, newParentId: string) =>
  (config: DashboardConfig): DashboardConfig => {
    return pipe(
      config,
      removeIdFromParentCell(cellId),
      updateParentId(cellId, newParentId),
      addIdToNewParentChildCellIds(cellId, newParentId),
      resizeLayoutsToFitChildren("root"),
      createMobileLayouts,
    );
  };
