import { CellInfo, DashboardConfig } from "../../configSchema";
import { filterUndefinedInfo } from "./cell-info";

/** returns an array of queryIds from the dashboard */
export const findAllQueryIds = (config: DashboardConfig | null): string[] => {
  if (!config) return [];
  const queryIds = Object.values(config.contents)
    .map((cell) => {
      if (cell?.variant !== "visualization") return undefined;
      return cell?.queryId;
    })
    .filter((id): id is string => id !== undefined);

  return [...new Set(queryIds)];
};

/**
 * Finds the tabs-panel cell in the dashboard config.
 */
export const findTabsPanelCells = (config?: DashboardConfig | null): CellInfo[] => {
  if (!config) return [];
  const cells = Object.values(config.cells);
  const foundCells = cells.filter((c): c is NonNullable<typeof c> => c?.variant === "tabs-panel");
  return filterUndefinedInfo(foundCells);
};
