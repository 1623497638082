import { CellInfo, CellLayout } from "../../configSchema";

export const layoutVariantDefaults: {
  [key in CellInfo["variant"]]?: Pick<CellLayout, "w" | "h">;
} = {
  visualization: {
    w: 12,
    h: 20,
  },
  params: {
    w: 12,
    h: 4,
  },
  layout: {
    w: 12,
    h: 20,
  },
  "tabs-panel": {
    w: 12,
    h: 30,
  },
  text: {
    w: 12,
    h: 20,
  },
};
