import { DashboardConfig } from "../../configSchema";
import { isCellContentVariant } from "../guards";
import { findCellContentById } from "./cell-content";
import { findCellInfoById } from "./cell-info";

/**
 * Finds the child param cell ID for a given cell ID by traversing up the cell hierarchy.
 * @param parentId The ID of the parent cell
 * @param config The DashboardConfig object
 * @returns The ID of the child param cell, or undefined if none is found
 */
export const findChildParamCellId =
  (parentId: string) =>
  (config: DashboardConfig): string | undefined => {
    const parentCell = findCellInfoById(parentId)(config);
    return parentCell?.childCellIds?.find((id) => id.includes("params"));
  };

/**
 * Finds the closest param cell ID for a given cell ID by traversing up the cell hierarchy.
 * @param cellId The ID of the cell to start the search from
 * @param dashboardView The view of the dashboard ("draft" or "published")
 * @returns A function that takes a DashboardConfig and returns the ID of the closest param cell, or undefined if none is found
 */
export const findClosestParamCellId =
  (cellId: string) =>
  (config: DashboardConfig | null): string | undefined => {
    if (!config) return undefined;
    const cell = findCellInfoById(cellId)(config);
    if (!cell) return undefined;

    if (cell.variant === "params") return cellId;

    let currentParentId = cell.parentCellId;

    while (currentParentId) {
      const parentCell = findCellInfoById(currentParentId)(config);
      if (!parentCell) return undefined;

      const paramCellId = parentCell.childCellIds?.find((id) => {
        const childCell = findCellInfoById(id)(config);
        return childCell?.variant === "params";
      });

      if (paramCellId) return paramCellId;

      if (currentParentId === "root") {
        const rootCell = findCellInfoById("root")(config);
        return rootCell?.childCellIds?.find((id) => id.includes("params"));
      }

      currentParentId = parentCell.parentCellId;
    }

    return undefined;
  };

/**
 * Recursively finds all unique query IDs from visualization cells that are descendants of a given parent cell.
 * The search is performed only if there are no "params" cells among the child cell IDs.
 *
 * @param parentId - The ID of the parent cell to start the search from.
 * @param dashboardView - The view of the dashboard ("draft" or "published").
 * @returns A function that takes a DashboardConfig and returns an array of unique query IDs from visualization cells.
 */
export const findDeepChildQueryIds =
  (parentId: string) =>
  (config: DashboardConfig | null): string[] => {
    if (!config) return [];
    const parentCell = findCellInfoById(parentId)(config);
    if (!parentCell) {
      return [];
    }

    const childIds = parentCell?.childCellIds ?? [];

    const childQueryIds = childIds.reduce<string[]>((acc, id) => {
      const cellContent = findCellContentById(id)(config);
      if (cellContent && isCellContentVariant(cellContent, "visualization")) {
        acc.push(cellContent.queryId);
      }

      const childCell = findCellInfoById(id)(config);
      if (childCell?.childCellIds) {
        const hasParamsCell = childCell.childCellIds.some((childId) => {
          const nestedCell = findCellInfoById(childId)(config);
          return nestedCell?.variant === "params";
        });

        if (!hasParamsCell) {
          acc.push(...findDeepChildQueryIds(id)(config));
        }
      }

      return acc;
    }, []);

    return [...new Set(childQueryIds)];
  };
